import { RUSSIA_COUNTRY_ID } from '~/common/constants';
import {
  CredentialsType,
  PaymentTab,
  TransactionStatus,
  type Credentials,
  PaymentTabTab,
} from '~/interfaces/publisher/payments';

export const paymentTabs = [
  {
    name: PaymentTab.PAYMENT,
    label: 'Выплаты',
    icon: 'solar:card-bold',
  },
  {
    name: PaymentTab.HISTORY,
    label: 'История',
    icon: 'iconamoon:history',
  },
];

export const mappedTransactionStatuses: Record<TransactionStatus, { label: string }> = {
  [TransactionStatus.CANCELED]: { label: 'Отменена' },
  [TransactionStatus.COMPLETE]: { label: 'Выплачена' },
  [TransactionStatus.DECLINED]: { label: 'Отклонена' },
  [TransactionStatus.ERROR]: { label: 'Ошибка' },
  [TransactionStatus.INITIATE]: { label: 'Зарегистрирована' },
  [TransactionStatus.WAIT]: { label: 'Ожидается' },
};

export const mappedLocalCredentialsTypeOptions = {
  [CredentialsType.SOLE_PROPRIETOR]: {
    value: CredentialsType.SOLE_PROPRIETOR,
    name: 'ИП',
  },
  [CredentialsType.COMPANY]: {
    value: CredentialsType.COMPANY,
    name: 'ООО',
  },
  [CredentialsType.SELF_EMPLOYED]: {
    value: CredentialsType.SELF_EMPLOYED,
    name: 'Самозанятый',
  },

  [CredentialsType.INDIVIDUAL]: {
    value: CredentialsType.INDIVIDUAL,
    name: 'Физическое лицо',
  },
};

const mappedForeignCredentialsTypeOptions = {
  [CredentialsType.INDIVIDUAL]: {
    value: CredentialsType.INDIVIDUAL,
    name: 'Made Task',
  },
  [CredentialsType.INTERNATIONAL]: {
    value: CredentialsType.INTERNATIONAL,
    name: 'Не подходит ни один вариант',
  },
};

export const mockCredentials: Credentials = {
  id: 1,
  type: CredentialsType.COMPANY,
  countryId: RUSSIA_COUNTRY_ID,
  data: {
    inn: '2133123213',
    bankName: 'Банк',
    bik: '213312312123',
    address: 'Курчатова 12',
    checkingAccount: '12331231233123123',
    treasuryAccount: '3123123123123123',
    phone: '79216750000',
    basedOnDocument: 'Документ',
    email: 'some@mail.ru',
    fullName: 'Полное имя реквизита',
    ogrn: '2312321123',
    kpp: '31231231323',
    organisationName: 'Наименование доброй организации',
  },
};

export const getMappedCredentialsTypeOptions = (countryId: number) => {
  if (countryId === RUSSIA_COUNTRY_ID) {
    return mappedLocalCredentialsTypeOptions;
  }
  return mappedForeignCredentialsTypeOptions;
};

export const getCredentialsName = (credentials: Credentials) => {
  const options = getMappedCredentialsTypeOptions(credentials.countryId);
  const typeName = options[credentials.type as keyof typeof options].name;
  const fullName =
    'fullName' in credentials.data
      ? credentials.data.fullName
      : Object.values(credentials.data.service)[0]?.email;
  return `${typeName} ${fullName}`;
};

export const mappedPaymentTabs = {
  [PaymentTabTab.paymentOrder]: 'Заказ выплаты',
  [PaymentTabTab.createCredentials]: 'Новые реквизиты',
  [PaymentTabTab.editCredentials]: 'Редактирование реквизитов',
};

export const passwordField = {
  inputLabel: 'Пароль',
  inputName: 'password',
  inputType: 'password',
};
